import { Commune } from "../commune-interface";
import { SanitizeString, searchHayStackUtil } from "./utils";
import { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
  TextField,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";

export default function GameInputComponent({
  communes,
  setCommunes,
}: {
  communes: Commune[];
  setCommunes: React.Dispatch<React.SetStateAction<Commune[]>>;
}) {
  const [wrongGuess, setWrongGuess] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [openResetConfirm, setOpenResetConfirm] = useState(false);
  const [openAboutDialog, setOpenAboutDialog] = useState(false);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const communeHash = Object.fromEntries(
    communes.map((c) => [SanitizeString(c.name), c.code])
  );
  const communesHayStack = Object.keys(communeHash);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    const found = searchHayStackUtil(communesHayStack, inputValue);
    if (!!found && validateInput(found, inputValue)) {
      let newCommunes = [...communes];
      const foundCommuneIdx = newCommunes.findIndex(
        (c) => c.code === communeHash[found]
      );
      newCommunes[foundCommuneIdx].timeFound = new Date();
      setCommunes(newCommunes);
      setWrongGuess(false);
    } else {
      setWrongGuess(true);
    }
    e.target.reset();
  };

  const handleReset = (e: any) => {
    e.preventDefault();
    let newCommunes = [...communes];
    newCommunes.forEach((c) => (c.timeFound = null));
    setCommunes(newCommunes);
    DismissResetDialog(e);
  };

  const handleResetRequest = (e: any) => {
    setOpenResetConfirm(true);
  };

  const DismissResetDialog = (e: any) => {
    setOpenResetConfirm(false);
    setAnchorEl(null);
  };

  const handleValueChanged = (e: any) => {
    setInputValue(e.target.value);
  };

  const validateInput = (found: string, needle: string) => {
    let tolerance = 2;
    if (found.length > 7) {
      tolerance = 5;
    }
    return Math.abs(found.length - needle.length) <= tolerance;
  };

  return (
    <Box display="flex" alignItems="center">
      <form autoComplete="off" onSubmit={handleFormSubmit}>
        <TextField
          id="outlined-search"
          label="Komin péyi a"
          type="search"
          error={wrongGuess}
          onChange={handleValueChanged}
          helperText={wrongGuess ? "faux" : ""}
          style={
            wrongGuess
              ? {
                  animation: "shake 0.5s",
                }
              : {}
          }
        />
      </form>
      <IconButton aria-label="delete" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuList>
          <MenuItem onClick={handleResetRequest}>
            <ListItemIcon>
              <RestartAltIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Recommencer</ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemIcon>
              <VolunteerActivismIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Soutenir le projet</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem onClick={() => setOpenAboutDialog(true)}>
            <ListItemIcon>
              <InfoIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>A propos</ListItemText>
          </MenuItem>
        </MenuList>
      </Popover>
      <Dialog
        open={openResetConfirm}
        onClose={DismissResetDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Reset game</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Vous allez perdre toute votre progression, êtes vous sûr ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={DismissResetDialog}>
            Non
          </Button>
          <Button variant="contained" onClick={handleReset} autoFocus>
            Oui
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openAboutDialog}
        onClose={() => {
          setOpenAboutDialog(false);
          setAnchorEl(null);
        }}
      >
        <DialogTitle>A propos</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Site web réalisé par FHocs (frederic@hocansung.com)
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
